import * as React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
    Chip,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import FunctionsIcon from "@mui/icons-material/Functions";
import { germanNumberFormat } from "../utils/formatting";
import { CustomTooltip } from "./CustomTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useContext } from "react";
import SidebarContext from "../contexts/SidebarContext";
import ResultTableContext from "../contexts/ResultTableContext";

/**
 * If the value of the property of the second object is less than the value of the property of the
 * first object, return -1. If the value of the property of the second object is greater than the value
 * of the property of the first object, return 1. Otherwise, return 0.
 * @param a - The first item to compare.
 * @param b - the second item in the array
 * @param orderBy - The name of the property to sort by.
 */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
 * If the order is descending, return a function that compares two rows and returns a number that is
 * less than 0 if the first row is greater than the second row, otherwise return a number that is
 * greater than 0 if the first row is less than the second row.
 * @param order - "asc" or "desc"
 * @param orderBy - the column name
 * @returns A function that takes two arguments (a, b) and returns a value.
 */
function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
/**
 * "The function takes an array and a comparator function, and returns a new array with the same
 * elements sorted by the comparator function."
 *
 * The function is used in the Material-UI Table component to sort the table rows.
 */
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

/**
 * It takes in a header object, and a list of columns that should be sortable, and returns a table head
 * component that has sortable columns.
 * @param props - order, orderBy, onRequestSort, header, sortColumns
 * @returns An object with the following properties:
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, header, sortColumns } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {Object.entries(header).map((headCell, index) => {
                    if (sortColumns.includes(headCell[1])) {
                        return (
                            <TableCell
                                key={headCell[0]}
                                align="right"
                                // padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell[0] ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell[0]}
                                    direction={orderBy === headCell[0] ? order : "asc"}
                                    onClick={createSortHandler(headCell[0])}
                                >
                                    {headCell[1] === "Performance Indikator Raumwärme [kWh/m²]" ? (
                                        <Typography variant="body2">
                                            {headCell[1]}
                                            <CustomTooltip title="Der Performance Indikator gibt auf Basis der angegebenen Verbrauchs und Zustandsdaten für das Gebäude an, wie der tatsächliche und erwartete Raumwärmeverbrauch in Relation stehen. Werte nahe 0 signalisieren, dass der angegebene Verbrauch nah an dem erwarteten Verbrauch liegt. Werte unter 0 indizieren, dass das Gebäude mehr verbraucht, als zu erwarten wäre.">
                                                <InfoIcon sx={{ mb: "-7px" }} color="neutral" />
                                            </CustomTooltip>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2">{headCell[1]}</Typography>
                                    )}
                                    {orderBy === headCell[0] ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        );
                    } else {
                        return (
                            <TableCell key={headCell[0]}>
                                <Typography variant="body2">{index === 0 ? null : headCell[1]}</Typography>
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

/**
 * EnhancedTableToolbar is a function that returns a Toolbar component with a Typography component
 * inside.
 * @param props - numSelected
 * @returns A React component.
 */
function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Typography
                sx={{ color: (theme) => theme.palette.text.secondary, flex: "1 1 100%" }}
                variant="h3"
                id="tableTitle"
                component="div"
            >
                {"Jahresergebnisse [" + props.dataYear + "]"}
            </Typography>
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {};

/**
 * Renders an enhanced table with sorting, pagination, and custom formatting.
 *
 * This component displays a table with various features such as sorting by columns, pagination,
 * and custom cell formatting. It is designed to handle large datasets efficiently. The table's appearance
 * and behavior are customizable through props. It supports displaying additional summary rows and
 * integrates with external sorting and pagination controls.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The data to display in the table.
 * @param {Object} props.header - An object mapping column keys to display names.
 * @param {string} props.colorColumn - The key of the column used for color coding.
 * @param {Function} props.colorScale - A function to determine the color based on the cell value.
 * @param {string} props.efficiencyClassColumn - The key of the column representing efficiency classes.
 * @param {string} props.expectedEfficiencyClassColumn - The key of the column representing expected efficiency classes.
 * @param {number} props.relationColumnIdx - The index of the column used for relational data comparison.
 * @param {Array} props.sortColumns - An array of column keys that are sortable.
 * @param {string} props.detailColumn - The key of the column used for detailed information.
 * @param {string} props.idColumn - The key of the column used for unique identifiers.
 * @param {string} props.link - The base URL for navigation links.
 * @param {Object} props.sumData - Optional summary data to display at the bottom of the table.
 * @param {string} props.dataYear - The year of the data being displayed.
 * @returns {JSX.Element} A box containing the table with enhanced features.
 *
 * @example
 * <EnhancedTable
 *   data={tableData}
 *   header={tableHeader}
 *   colorColumn="Performance Indicator"
 *   colorScale={colorScaleFunction}
 *   efficiencyClassColumn="Efficiency Class"
 *   expectedEfficiencyClassColumn="Expected Efficiency Class"
 *   relationColumnIdx={3}
 *   sortColumns={["ID", "Address"]}
 *   detailColumn="Address"
 *   idColumn="ID"
 *   link="/details"
 *   sumData={summaryData}
 *   dataYear="2021"
 * />
 */
export default function EnhancedTable(props) {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("building_address");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(400);
    const { filteredBuildings, setFilteredBuildings } = React.useContext(ResultTableContext);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

    React.useEffect(() => {
        if (props.data.length < page * rowsPerPage) {
            setPage(0);
        }
    }, [page, rowsPerPage, props.data.length]);

    return (
        <Box sx={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
            <TableContainer
                sx={{
                    width: "100%",
                    maxHeight: "calc(100vh - 200px)",
                    overflow: "hidden",
                    overflowY: "scroll",
                    overflowX: "scroll",
                    display: "grid",
                    flexDirection: "column",
                }}
            >
                <Table stickyHeader={true} sx={{}} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.data.length + 1}
                        header={props.header}
                        sortColumns={props.sortColumns}
                    />
                    <TableBody>
                        {stableSort(Object.values(props.data), getComparator(order, orderBy))
                            .slice(
                                filteredBuildings.length > 0 ? 0 : page * rowsPerPage,
                                filteredBuildings.length > 0
                                    ? Object.values(props.data).length
                                    : page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                if (filteredBuildings.length === 0 || filteredBuildings.includes(row.building_id)) {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.building_address}>
                                            {Object.values(row).map((value, innerIndex) => {
                                                let cellColor = "";
                                                let textColor = "";
                                                if (Object.values(props.header)[innerIndex] === props.colorColumn) {
                                                    cellColor = props.colorScale(
                                                        value,
                                                        Object.values(row)[props.relationColumnIdx]
                                                    );
                                                    textColor = "black";
                                                    return (
                                                        <TableCell
                                                            key={innerIndex}
                                                            align="right"
                                                            sx={{ color: textColor }}
                                                        >
                                                            {germanNumberFormat(value)}
                                                            <LocalFireDepartmentIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: cellColor,
                                                                    mb: -1.4,
                                                                }}
                                                            />
                                                        </TableCell>
                                                    );
                                                }
                                                if (
                                                    Object.values(props.header)[innerIndex] ===
                                                        props.efficiencyClassColumn ||
                                                    Object.values(props.header)[innerIndex] ===
                                                        props.expectedEfficiencyClassColumn
                                                ) {
                                                    textColor = "black";
                                                    return (
                                                        <TableCell
                                                            key={innerIndex}
                                                            align="center"
                                                            sx={{ color: textColor, width: "50px" }}
                                                        >
                                                            <Chip
                                                                label={value.name}
                                                                sx={{
                                                                    backgroundColor: "#" + value.color,
                                                                    fontWeight: "bold",
                                                                }}
                                                            />
                                                        </TableCell>
                                                    );
                                                }
                                                if (
                                                    props.sortColumns.includes(Object.values(props.header)[innerIndex])
                                                ) {
                                                    value = germanNumberFormat(value);
                                                }
                                                if (Object.values(props.header)[innerIndex] === props.idColumn) {
                                                    return (
                                                        <TableCell
                                                            key={innerIndex}
                                                            style={{
                                                                backgroundColor: cellColor,
                                                                minWidth: 0,
                                                            }}
                                                        >
                                                            <ExtendedEditButton link={props.link + "/" + value} />
                                                        </TableCell>
                                                    );
                                                }
                                                if (Object.values(props.header)[innerIndex] === props.detailColumn) {
                                                    return (
                                                        <TableCell
                                                            key={innerIndex}
                                                            style={{
                                                                backgroundColor: cellColor,
                                                                minWidth: 200,
                                                            }}
                                                        >
                                                            {value}
                                                        </TableCell>
                                                    );
                                                }
                                                return (
                                                    <TableCell
                                                        key={innerIndex}
                                                        style={{
                                                            backgroundColor: cellColor,
                                                        }}
                                                        align="right"
                                                        sx={{ color: textColor }}
                                                    >
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }
                            })}
                        {props.sumData && !filteredBuildings.length > 0 ? (
                            <TableRow hover tabIndex={-1} key="sum" sx={{ backgroundColor: "#e6e6e6" }}>
                                {Object.values(props.sumData).map((value, innerIndex) => {
                                    let cellColor = "";
                                    let textColor = "";
                                    if (Object.values(props.header)[innerIndex] === props.colorColumn) {
                                        cellColor = props.colorScale(
                                            value,
                                            Object.values(props.sumData)[props.relationColumnIdx]
                                        );
                                        textColor = "black";
                                        return (
                                            <TableCell key={innerIndex} align="right" sx={{ color: textColor }}>
                                                {germanNumberFormat(value)}
                                                <LocalFireDepartmentIcon
                                                    fontSize="large"
                                                    sx={{
                                                        color: cellColor,
                                                        mb: -1.4,
                                                    }}
                                                />
                                            </TableCell>
                                        );
                                    }

                                    if (
                                        Object.values(props.header)[innerIndex] === props.efficiencyClassColumn ||
                                        Object.values(props.header)[innerIndex] === props.expectedEfficiencyClassColumn
                                    ) {
                                        textColor = "black";
                                        return (
                                            <TableCell
                                                key={innerIndex}
                                                align="center"
                                                sx={{ color: textColor, width: "50px" }}
                                            >
                                                <Chip
                                                    label={value.name}
                                                    sx={{
                                                        backgroundColor: "#" + value.color,
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                            </TableCell>
                                        );
                                    }

                                    if (props.sortColumns.includes(Object.values(props.header)[innerIndex])) {
                                        value = germanNumberFormat(value);
                                    }
                                    if (Object.values(props.header)[innerIndex] === props.idColumn) {
                                        return (
                                            <TableCell
                                                key={innerIndex}
                                                style={{
                                                    backgroundColor: cellColor,
                                                    minWidth: 0,
                                                }}
                                            >
                                                <FunctionsIcon sx={{ mt: "4px", ml: "3px", fontSize: 22 }} />
                                            </TableCell>
                                        );
                                    }
                                    if (Object.values(props.header)[innerIndex] === props.detailColumn) {
                                        return (
                                            <TableCell
                                                key={innerIndex}
                                                style={{
                                                    backgroundColor: cellColor,
                                                    minWidth: 200,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    }
                                    return (
                                        <TableCell
                                            key={innerIndex}
                                            style={{
                                                backgroundColor: cellColor,
                                            }}
                                            align="right"
                                            sx={{ color: textColor }}
                                        >
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ) : (
                            <></>
                        )}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 400]}
                component="div"
                // sx={{ display: "flex", justifyContent: "left" }}
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Gebäude pro Seite:"
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to ? to : 0} von ${count ? (count !== -1 ? count : `mehr als ${to}`) : 0}`;
                }}
                showFirstButton
                showLastButton
            />
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dichte Darstellung"
            /> */}
        </Box>
    );
}

/**
 * It's a Material UI IconButton that links to a page.
 * @param props - link
 * @returns A React component.
 */
function ExtendedEditButton(props) {
    // setSelected
    const { setSelected } = useContext(SidebarContext);
    return (
        <IconButton
            aria-label="edit"
            component={Link}
            to={props.link}
            onClick={(event) => {
                setSelected("Gebäudedetails");
            }}
            sx={{ mr: 1, boxShadow: 3, borderColor: "blue", border: 1 }}
            size="small"
            color="secondary"
        >
            <EditIcon fontSize="inherit" />
        </IconButton>
    );
}
