/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { useImmer } from "use-immer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Slider,
    Tabs,
    Tab,
    Fab,
    Modal,
    Divider,
    Grid,
    InputAdornment,
    Alert,
    Button,
    SliderThumb,
    SliderMark,
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    ToggleButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import InsightsTwoToneIcon from "@mui/icons-material/InsightsTwoTone";
import api_client from "../../../services/http-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConstructionIcon from "@mui/icons-material/Construction";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { ConsumptionScale } from "../../../components/ResultBarGauges";
import {
    addDisplayNumbersInJson,
    addDisplayNumbersInJsonArray,
    cleanNumbersInJson,
    createGermanNumberString,
} from "../../../utils/formatting";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastContext from "../../../contexts/ToastContext";
import UpdateTimeStampContext from "../UpdateTimestampContext";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { linearInterpolation } from "../../../utils/calculation";
import { BoundedIntegerInput, FloatInput, IntegerInput, BasicTextField } from "../../../components/InputFields";
import EnergyEfficiencyClassesContext from "../EfficiencyClassesContext";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { CustomAccordion, CustomAccordionSummary, CustomAccordionDetails } from "../../../components/CustomAccordion";
import BuildingComponentParameterAccordion from "./BuildingComponentParameters";
import zIndex from "@mui/material/styles/zIndex";

const NumberInput = styled(BasicTextField)`
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        display: none;
    }
`;

const SmallTableCell = styled(TableCell)(({ theme }) => ({
    paddingTop: 0.3,
    paddingBottom: 0,
}));

/**
 * Component for displaying and managing building conditions in an accordion format.
 *
 * This component allows users to view, add, edit, and delete building conditions for a specific building.
 * It integrates with various contexts to fetch and display data related to energy efficiency, modernization,
 * and building statistics. It also provides interactive elements like sliders, inputs, and modals for
 * a dynamic user experience.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the conditions are being managed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with building condition details and management options.
 */
export const BuildingConditionsAccordion = ({ currentBuilding }) => {
    // -- CONTEXT -- //
    const { addToastMessage } = useContext(ToastContext);
    const { setUpdateTimestamp } = useContext(UpdateTimeStampContext);
    const { energyEfficiencyClasses } = useContext(EnergyEfficiencyClassesContext);
    // -- CONSTANTS -- //
    const theme = useTheme();

    const emptyBuildingCondition = {
        year: 2000,
        note: "",
        living_space: { display: "", real: 0 },
        apartments: { display: "", real: 0 },
        apartments_with_cf_waterheating: { display: "", real: 0 },
        component_modernization_hull: { display: 0, real: 0 },
        component_modernization_windows: { display: 0, real: 0 },
        component_modernization_roof: { display: 0, real: 0 },
        component_modernization_cellarroof: { display: 0, real: 0 },
        component_modernization_ventilation: { display: 0, real: 0 },
        total_degree_of_modernization: { display: 0, real: 0 },
        full_storeys: { display: "", real: 0 },
        full_storey_height: { display: "", real: 0 },
        statistical_consumption_roomheating: 0,
        statistical_consumption_waterheating: 0,
        component_parameters: { hull: [], windows: [], roof: [], cellarroof: [] },
        modernization_costs: { estimated: null, userinput: null },
    };

    const modernization_degree_modifier = 1.0 / 0.75;

    // -- STATES -- //
    // building-condition data
    const [buildingConditionTab, setBuildingConditionTab] = useState(0);
    const [buildingCondition, setBuildingCondition] = useImmer([emptyBuildingCondition]);

    const [fetchingBuildingConditionData, setFetchingBuildingConditionData] = useState(false);

    // handle functions for addYearModal in building-conditions
    const [addConditionYearModalOpen, setAddConditionYearModalOpen] = useState(false);
    const [addConditionYearChoice, setAddConditionYearChoice] = useState();
    const [addConditionYearValid, setAddConditionYearValid] = useState(false);
    const handleOpenAddConditionYear = () => setAddConditionYearModalOpen(true);
    const handleCloseAddConditionYear = () => setAddConditionYearModalOpen(false);

    const [editModeBuildingConditions, setEditModeBuildingConditions] = useState(false);
    const [errorBuildingConditions, setErrorBuildingConditions] = useImmer({});

    const [errorBuildingComponentParameters, setErrorBuildingComponentParameters] = useImmer({});

    // statistical consumption data
    const [statisticalBuildingConsumptions, setStatisticalBuildingConsumptions] = useState();

    // building-condition reference data
    const [ComponentModernizationReferences, setComponentModernizationReferences] = useState();
    const [ComponentModernizationSteps, setComponentModernizationSteps] = useState({
        roof: { steps: [], max_modernization_impact_pct: 0 },
        hull: { steps: [], max_modernization_impact_pct: 0 },
        cellarroof: { steps: [], max_modernization_impact_pct: 0 },
        windows: { steps: [], max_modernization_impact_pct: 0 },
        ventilation: { steps: [], max_modernization_impact_pct: 0 },
    });
    const [modernizationSliderMarks, setModernizationSliderMarks] = useState({
        hull: [{ value: 0, label: "0" }],
        roof: [{ value: 0, label: "0" }],
        cellarroof: [{ value: 0, label: "0" }],
        windows: [{ value: 0, label: "0" }],
        ventilation: [{ value: 0, label: "0" }],
    });

    // deletion modal
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    // nested accordion expanded state
    const [nestedAccordionExpanded, setNestedAccordionExpanded] = useState(0); // 0 = all collapsed, 1 = modernisierungszustand expanded, 2 = bauteilparameter expanded
    const handleNestedAccordionChange = (panel) => (event, isExpanded) => {
        setNestedAccordionExpanded(isExpanded ? panel : false);
    };

    // toggle for cost display (estimated / userinput values)
    const [showEstimatedCosts, setShowEstimatedCosts] = useState(
        buildingCondition[buildingConditionTab].modernization_costs.userinput === null
    );

    // userinput modernization costs modal
    const [userInputModernizationCostsModalOpen, setUserInputModernizationCostsModalOpen] = useState(false);
    const handleOpenUserInputModernizationCostsModal = () => setUserInputModernizationCostsModalOpen(true);
    const handleCloseUserInputModernizationCostsModal = () => setUserInputModernizationCostsModalOpen(false);

    // -- FUNCTIONS -- //
    // check object (may contain arrays or other objects) recursively for false values
    const checkObjectForTrue = (obj) => {
        for (var key in obj) {
            if (obj[key] === true) {
                return true;
            }
            if (typeof obj[key] === "object") {
                if (checkObjectForTrue(obj[key])) {
                    return true;
                }
            }
        }
        return false;
    };

    // handle the switching of building-conditions year (tab)
    const handleBuildingConditionTab = (event, newValue) => {
        setBuildingConditionTab(newValue);
    };

    const getModernizationReferenceDescription = (
        modernizationImpactPct,
        componentModernizationReferences,
        displayUValue = true
    ) => {
        /*
        Issue #73 https://github.com/lukas-aldenhoff/ARKcalculator/issues/73
        */
        let prevIdx = null;
        let currentIdx = null;
        for (let [idx] of componentModernizationReferences.entries()) {
            currentIdx = idx;
            if (componentModernizationReferences[currentIdx].modernization_impact_pct === modernizationImpactPct) {
                prevIdx = null;
                break;
            }
            if (componentModernizationReferences[currentIdx].modernization_impact_pct > modernizationImpactPct) {
                break;
            }
            prevIdx = currentIdx;
        }
        let description = "";
        if (!displayUValue) {
            description = componentModernizationReferences[currentIdx].description;
            if (prevIdx !== null) {
                description = (
                    <>
                        {"Zwischen:"}
                        <br />
                        {prevIdx.toString() + ": " + componentModernizationReferences[prevIdx].description}
                        <br />
                        {currentIdx.toString() + ": " + description}
                    </>
                );
            }
        } else {
            description =
                "U-Wert von " +
                createGermanNumberString(componentModernizationReferences[currentIdx].u_value) +
                ". Entspricht etwa " +
                componentModernizationReferences[currentIdx].description;

            if (prevIdx !== null) {
                description = (
                    <>
                        {"Zwischen:"}
                        <br />
                        {prevIdx.toString() +
                            ": " +
                            ("U-Wert von " +
                                createGermanNumberString(componentModernizationReferences[prevIdx].u_value) +
                                ". Entspricht etwa " +
                                componentModernizationReferences[prevIdx].description)}
                        <br />
                        {currentIdx.toString() + ": " + description}
                    </>
                );
            }
        }
        return description;
    };

    const setTotalModernization = () => {
        setBuildingCondition((cond) => {
            var deg =
                cond[buildingConditionTab].component_modernization_hull.weighted +
                cond[buildingConditionTab].component_modernization_windows.weighted +
                cond[buildingConditionTab].component_modernization_roof.weighted +
                cond[buildingConditionTab].component_modernization_cellarroof.weighted +
                cond[buildingConditionTab].component_modernization_ventilation.weighted;
            cond[buildingConditionTab].total_degree_of_modernization.real = Number(
                (deg * modernization_degree_modifier).toFixed(0)
            );
            cond[buildingConditionTab].statistical_consumption_roomheating = linearInterpolation(
                deg,
                0,
                75,
                statisticalBuildingConsumptions.gross_energy_roomheating_values
            );
            cond[buildingConditionTab].statistical_consumption_waterheating = linearInterpolation(
                deg,
                0,
                75,
                statisticalBuildingConsumptions.gross_energy_waterheating_values
            );
        });
    };

    // fetch building conditions from backend
    const fetchBuildingConditionData = useCallback(async () => {
        setEditModeBuildingConditions(false);
        setFetchingBuildingConditionData(true);
        api_client.get(`/buildings/${currentBuilding.id}/conditions/`, {}).then((response) => {
            const data = addDisplayNumbersInJsonArray(response.data.conditions);
            const statistical_data = response.data.statistical_consumption_data;

            var adapted_data = data.map((val) => {
                var tmp = {
                    ...val,
                    component_modernization_cellarroof: {
                        display: getComponentModernizationDisplayValue(
                            ComponentModernizationSteps.cellarroof,
                            val.component_modernization_cellarroof.real
                        ),
                        weighted:
                            (val.component_modernization_cellarroof.real / 100) *
                            ComponentModernizationSteps.cellarroof.max_modernization_impact_pct,
                        real: val.component_modernization_cellarroof.real,
                    },
                    component_modernization_roof: {
                        display: getComponentModernizationDisplayValue(
                            ComponentModernizationSteps.roof,
                            val.component_modernization_roof.real
                        ),
                        weighted:
                            (val.component_modernization_roof.real / 100) *
                            ComponentModernizationSteps.roof.max_modernization_impact_pct,
                        real: val.component_modernization_roof.real,
                    },
                    component_modernization_hull: {
                        display: getComponentModernizationDisplayValue(
                            ComponentModernizationSteps.hull,
                            val.component_modernization_hull.real
                        ),
                        weighted:
                            (val.component_modernization_hull.real / 100) *
                            ComponentModernizationSteps.hull.max_modernization_impact_pct,
                        real: val.component_modernization_hull.real,
                    },
                    component_modernization_ventilation: {
                        display: getComponentModernizationDisplayValue(
                            ComponentModernizationSteps.ventilation,
                            val.component_modernization_ventilation.real
                        ),
                        weighted:
                            (val.component_modernization_ventilation.real / 100) *
                            ComponentModernizationSteps.ventilation.max_modernization_impact_pct,
                        real: val.component_modernization_ventilation.real,
                    },
                    component_modernization_windows: {
                        display: getComponentModernizationDisplayValue(
                            ComponentModernizationSteps.windows,
                            val.component_modernization_windows.real
                        ),
                        weighted:
                            (val.component_modernization_windows.real / 100) *
                            ComponentModernizationSteps.windows.max_modernization_impact_pct,
                        real: val.component_modernization_windows.real,
                    },
                    total_degree_of_modernization: {
                        display: 0,
                        real: Number(
                            (val.total_degree_of_modernization.real * modernization_degree_modifier).toFixed(0)
                        ),
                    },
                };
                tmp.statistical_consumption_roomheating = linearInterpolation(
                    val.total_degree_of_modernization.real,
                    0,
                    75,
                    statistical_data.gross_energy_roomheating_values
                );
                tmp.statistical_consumption_waterheating = linearInterpolation(
                    val.total_degree_of_modernization.real,
                    0,
                    75,
                    statistical_data.gross_energy_waterheating_values
                );
                return tmp;
            });

            // assign local states
            setStatisticalBuildingConsumptions(statistical_data);
            // if we were previously on the last tab, we want to stay on the last tab, else we stay on the current tab (or the last tab if the current tab is not available anymore)
            var tab =
                buildingConditionTab === buildingCondition.length - 1
                    ? data.length - 1
                    : Math.max(0, Math.min(data.length - 1, buildingConditionTab));
            // update the building condition data
            setBuildingCondition((cond) => {
                cond.splice(0, cond.length);
                adapted_data.map((val) => {
                    cond.push(val);
                    return cond;
                });
            });
            setBuildingConditionTab(tab);
            setFetchingBuildingConditionData(false);
        });
    }, [currentBuilding, buildingConditionTab]);

    // fetch building condition reference data
    const fetchBuildingConditionReferenceData = useCallback(async () => {
        setFetchingBuildingConditionData(true);
        api_client.get(`/buildings/${currentBuilding.id}/reference/`, {}).then((response) => {
            const references = response.data.references;
            const modernization_steps = response.data.modernization_steps;

            // assign local state
            setComponentModernizationSteps(modernization_steps);
            setComponentModernizationReferences(references);

            setModernizationSliderMarks({
                hull: references.hull.map((el, idx) => {
                    return {
                        value: modernization_steps.hull.steps.indexOf(
                            el.modernization_impact_pct / (modernization_steps.hull.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
                windows: references.windows.map((el, idx) => {
                    return {
                        value: modernization_steps.windows.steps.indexOf(
                            el.modernization_impact_pct /
                                (modernization_steps.windows.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
                roof: references.roof.map((el, idx) => {
                    return {
                        value: modernization_steps.roof.steps.indexOf(
                            el.modernization_impact_pct / (modernization_steps.roof.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
                cellarroof: references.cellarroof.map((el, idx) => {
                    return {
                        value: modernization_steps.cellarroof.steps.indexOf(
                            el.modernization_impact_pct /
                                (modernization_steps.cellarroof.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),

                ventilation: references.ventilation.map((el, idx) => {
                    return {
                        value: modernization_steps.ventilation.steps.indexOf(
                            el.modernization_impact_pct /
                                (modernization_steps.ventilation.max_modernization_impact_pct / 100)
                        ),
                        label: idx,
                    };
                }),
            });
        });
        setFetchingBuildingConditionData(false);
    }, [currentBuilding]);

    useEffect(() => {
        fetchBuildingConditionData();
        fetchBuildingConditionReferenceData();
    }, [currentBuilding]);

    // get the sum of the areas of a component
    const getComponentAreaSum = useCallback(
        (component) => {
            for (
                let index = 0;
                index < buildingCondition[buildingConditionTab].component_parameters[component].length;
                index++
            ) {
                const parameter = buildingCondition[buildingConditionTab].component_parameters[component][index];
                if (parameter.area_separator === true) {
                    return createGermanNumberString(
                        parameter.choices.reduce(
                            (acc, val) =>
                                val.user_input_area.real
                                    ? acc + val.user_input_area.real
                                    : acc + val.calculated_area.real,
                            0
                        ),
                        true,
                        2
                    );
                }
            }
            return 0;
        },
        [buildingCondition, buildingConditionTab]
    );

    const getComponentModernizationDisplayValue = (componentModernizationSteps, realValue) => {
        for (let i = 0; i < componentModernizationSteps.steps.length; i++) {
            if (componentModernizationSteps.steps[i] === realValue) {
                return i;
            }
        }
        return null;
    };

    // set the display values for the component modernization (for the sliders)
    const setComponentModernizationDisplayValues = () => {
        if (!ComponentModernizationSteps) {
            return;
        }
        setBuildingCondition((cond) => {
            cond.forEach((condEl) => {
                condEl.component_modernization_hull.display = getComponentModernizationDisplayValue(
                    ComponentModernizationSteps.hull,
                    condEl.component_modernization_hull.real
                );
                condEl.component_modernization_windows.display = getComponentModernizationDisplayValue(
                    ComponentModernizationSteps.windows,
                    condEl.component_modernization_windows.real
                );
                condEl.component_modernization_roof.display = getComponentModernizationDisplayValue(
                    ComponentModernizationSteps.roof,
                    condEl.component_modernization_roof.real
                );
                condEl.component_modernization_cellarroof.display = getComponentModernizationDisplayValue(
                    ComponentModernizationSteps.cellarroof,
                    condEl.component_modernization_cellarroof.real
                );
                condEl.component_modernization_ventilation.display = getComponentModernizationDisplayValue(
                    ComponentModernizationSteps.ventilation,
                    condEl.component_modernization_ventilation.real
                );

                condEl.component_modernization_hull.weighted =
                    (condEl.component_modernization_hull.real / 100) *
                    ComponentModernizationSteps.hull.max_modernization_impact_pct;
                condEl.component_modernization_windows.weighted =
                    (condEl.component_modernization_windows.real / 100) *
                    ComponentModernizationSteps.windows.max_modernization_impact_pct;
                condEl.component_modernization_roof.weighted =
                    (condEl.component_modernization_roof.real / 100) *
                    ComponentModernizationSteps.roof.max_modernization_impact_pct;
                condEl.component_modernization_cellarroof.weighted =
                    (condEl.component_modernization_cellarroof.real / 100) *
                    ComponentModernizationSteps.cellarroof.max_modernization_impact_pct;
                condEl.component_modernization_ventilation.weighted =
                    (condEl.component_modernization_ventilation.real / 100) *
                    ComponentModernizationSteps.ventilation.max_modernization_impact_pct;
            });
        });
    };

    // useEffect to update component modernization display values when ComponentModernizationSteps Data is present
    useEffect(() => {
        setComponentModernizationDisplayValues();
    }, [ComponentModernizationSteps, buildingCondition[buildingConditionTab]]);

    // delete building condition
    const deleteBuildingCondition = async () => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingConditionData(true);
        api_client
            .delete(`/buildings/${currentBuilding.id}/conditions/${buildingCondition[buildingConditionTab].year}/`, {})
            .then((response) => {
                setBuildingConditionTab(Math.max(0, Math.min(buildingCondition.length - 2, buildingConditionTab)));
                if (response.status === 200) {
                    fetchBuildingConditionData();
                    setUpdateTimestamp(Date.now());
                    addToastMessage(
                        "Gebäudezustand erfolgreich gelöscht.",
                        "success",
                        true,
                        6000,
                        "toast-delete-condition-success"
                    );
                } else {
                    addToastMessage(
                        "Beim Löschen des Gebäudezustandes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000,
                        "toast-delete-condition-error"
                    );
                }
                setFetchingBuildingConditionData(false);
            });
    };

    // add years (tabs) to building conditions
    const addBuildingConditionTab = () => {
        handleCloseAddConditionYear();
        const newTabIdx = buildingCondition.length;
        setBuildingCondition((cond) => {
            var lastEl;
            if (buildingCondition.length > 0) {
                lastEl = { ...buildingCondition[buildingCondition.length - 1] };
            } else {
                lastEl = { ...emptyBuildingCondition };
            }
            lastEl.year = parseInt(addConditionYearChoice);

            lastEl.statistical_consumption_roomheating =
                statisticalBuildingConsumptions.gross_energy_roomheating_values[0];
            lastEl.statistical_consumption_waterheating =
                statisticalBuildingConsumptions.gross_energy_waterheating_values[0];
            cond.push(lastEl);
        });
        setAddConditionYearChoice("");
        setAddConditionYearValid(false);
        setBuildingConditionTab(newTabIdx);
        setEditModeBuildingConditions(true);
    };

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingConditions === true) {
            fetchBuildingConditionData();
            setEditModeBuildingConditions(false);
        } else {
            setEditModeBuildingConditions(true);
        }
        setErrorBuildingConditions({});
    };

    // handle the saving after editing
    const handleSave = async () => {
        setEditModeBuildingConditions((prevEditMode) => !prevEditMode);
        setFetchingBuildingConditionData(true);
        var _buildingCondition = [cleanNumbersInJson(buildingCondition[buildingConditionTab])];
        // post building-conditions to backend
        api_client.post(`/buildings/${currentBuilding.id}/conditions/`, _buildingCondition).then((response) => {
            const building_condition_status = response.status;
            if (building_condition_status === 200) {
                const data = addDisplayNumbersInJsonArray(response.data.conditions);
                setBuildingCondition((cond) => {
                    cond[buildingConditionTab] = data[0];
                    cond[buildingConditionTab].statistical_consumption_roomheating = linearInterpolation(
                        data[0].total_degree_of_modernization.real,
                        0,
                        75,
                        statisticalBuildingConsumptions.gross_energy_roomheating_values
                    );
                    cond[buildingConditionTab].statistical_consumption_waterheating = linearInterpolation(
                        data[0].total_degree_of_modernization.real,
                        0,
                        75,
                        statisticalBuildingConsumptions.gross_energy_waterheating_values
                    );
                    cond[buildingConditionTab].total_degree_of_modernization.real = Number(
                        (data[0].total_degree_of_modernization.real * modernization_degree_modifier).toFixed(0)
                    );
                });
            }
            setUpdateTimestamp(Date.now());
            setFetchingBuildingConditionData(false);
        });
    };

    const UserInputModernizationCostsModal = ({ currentUserInputCosts }) => {
        const [userInputCosts, setUserInputCosts] = useImmer(
            currentUserInputCosts
                ? { ...currentUserInputCosts }
                : addDisplayNumbersInJson({ cost_hull: 0, cost_windows: 0, cost_roof: 0, cost_cellarroof: 0 })
        );

        const [errorUserInputCosts, setErrorUserInputCosts] = useImmer({
            cost_hull: false,
            cost_windows: false,
            cost_roof: false,
            cost_cellarroof: false,
        });

        // handle the saving of the user input modernization costs
        const handleSaveUserInputModernizationCosts = async () => {
            setFetchingBuildingConditionData(true);
            var _userInputCosts = cleanNumbersInJson(userInputCosts);

            // post userinput modernization costs to backend
            api_client
                .post(
                    `/buildings/${currentBuilding.id}/conditions/${buildingCondition[buildingConditionTab].year}/modernization-costs/`,
                    {
                        ..._userInputCosts,
                    }
                )
                .then((response) => {
                    const modernization_costs_status = response.status;
                    if (modernization_costs_status === 200) {
                        const data = addDisplayNumbersInJson(response.data.data);
                        setBuildingCondition((cond) => {
                            cond[buildingConditionTab].modernization_costs.userinput = data;
                        });
                    }
                    setFetchingBuildingConditionData(false);
                })
                .catch((error) => {
                    addToastMessage("Fehler beim Speichern der Modernisierungskosten.", "error");
                    setFetchingBuildingConditionData(false);
                });
        };

        return (
            <Modal
                open={userInputModernizationCostsModalOpen}
                onClose={handleCloseUserInputModernizationCostsModal}
                aria-labelledby="user-input-modernization-costs-modal"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "60ch",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Button
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "5px",
                        }}
                        onClick={() => handleCloseUserInputModernizationCostsModal()}
                    >
                        <CloseIcon />
                    </Button>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 1 }}>
                        Geben Sie, sofern vorhanden, die entstandenen Modernisierungskosten ein
                    </Typography>
                    <Box height="7ch">
                        {/* Float Input für Fassaden Kosten */}
                        <FloatInput
                            value={userInputCosts.cost_hull}
                            variant="outlined"
                            onChange={(val) => {
                                setUserInputCosts((prev) => {
                                    prev.cost_hull = val;
                                });
                            }}
                            sx={{ mt: 1, mr: 2, width: "46%" }}
                            size="small"
                            label="Kosten Fassade"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            validate={(val) => {
                                return true;
                            }}
                            error={errorUserInputCosts.cost_hull}
                            setError={(val) => {
                                setErrorUserInputCosts((err) => {
                                    err.cost_hull = val;
                                });
                            }}
                        />
                        {/* Float Input für Dach Kosten */}
                        <FloatInput
                            value={userInputCosts.cost_roof}
                            variant="outlined"
                            onChange={(val) => {
                                setUserInputCosts((prev) => {
                                    prev.cost_roof = val;
                                });
                            }}
                            sx={{ mt: 1, mr: 2, width: "46%" }}
                            size="small"
                            label="Kosten Dach"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            validate={(val) => {
                                return true;
                            }}
                            error={errorUserInputCosts.cost_roof}
                            setError={(val) => {
                                setErrorUserInputCosts((err) => {
                                    err.cost_roof = val;
                                });
                            }}
                        />
                    </Box>
                    <Box height="7ch">
                        {/* Float Input für Fenster Kosten */}
                        <FloatInput
                            value={userInputCosts.cost_windows}
                            variant="outlined"
                            onChange={(val) => {
                                setUserInputCosts((prev) => {
                                    prev.cost_windows = val;
                                });
                            }}
                            sx={{ mt: 1, mr: 2, width: "46%" }}
                            size="small"
                            label="Kosten Fenster"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            validate={(val) => {
                                return true;
                            }}
                            error={errorUserInputCosts.cost_windows}
                            setError={(val) => {
                                setErrorUserInputCosts((err) => {
                                    err.cost_windows = val;
                                });
                            }}
                        />
                        {/* Float Input für Kellerdecke Kosten */}
                        <FloatInput
                            value={userInputCosts.cost_cellarroof}
                            variant="outlined"
                            onChange={(val) => {
                                setUserInputCosts((prev) => {
                                    prev.cost_cellarroof = val;
                                });
                            }}
                            sx={{ mt: 1, mr: 2, width: "46%" }}
                            size="small"
                            label="Kosten Keller(-decke)"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            validate={(val) => {
                                return true;
                            }}
                            error={errorUserInputCosts.cost_cellarroof}
                            setError={(val) => {
                                setErrorUserInputCosts((err) => {
                                    err.cost_cellarroof = val;
                                });
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            ml: "-8%",
                            mt: 1,
                        }}
                    >
                        <Fab
                            variant="extended"
                            size="medium"
                            onClick={(e) => {
                                setBuildingCondition((cond) => {
                                    cond[buildingConditionTab].modernization_costs.userinput = userInputCosts;
                                });
                                handleSaveUserInputModernizationCosts();
                                handleCloseUserInputModernizationCostsModal();
                            }}
                            color="secondary"
                            sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1, width: "28ch" }}
                            disabled={
                                checkObjectForTrue(errorUserInputCosts) || Object.keys(errorUserInputCosts).length === 0
                            }
                        >
                            <SaveIcon sx={{ mr: 1 }} />
                            Speichern
                        </Fab>
                    </Box>
                </Box>
            </Modal>
        );
    };

    const ModernizationSlider = ({
        previousModernizationValue,
        componentModernizationKey,
        componentModernizationSteps,
        modernizationSliderMarks,
    }) => {
        function CustomSliderMarkComponent(props) {
            const { children, ...other } = props;
            return other["data-index"] !== 0 ? (
                <SliderMark {...other} />
            ) : (
                <SliderThumb
                    {...other}
                    sx={{
                        width: 15,
                        height: 15,
                        mt: 0,
                        backgroundColor: "lightgrey",
                        zIndex: 0,
                        borderRadius: "25%",
                        "& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover": {
                            boxShadow: `none !important`,
                        },
                    }}
                >
                    {children}
                </SliderThumb>
            );
        }

        return (
            <Slider
                color="secondary"
                aria-label="Prozent"
                defaultValue={0}
                valueLabelDisplay="off"
                step={1}
                marks={
                    previousModernizationValue
                        ? [
                              {
                                  value: previousModernizationValue,
                                  label: "",
                              },
                              ...modernizationSliderMarks,
                          ]
                        : [0, ...modernizationSliderMarks]
                }
                min={0}
                max={componentModernizationSteps.steps.length - 1}
                disabled={!editModeBuildingConditions}
                value={buildingCondition[buildingConditionTab][componentModernizationKey].display}
                onChange={(e) => {
                    setBuildingCondition((cond) => {
                        cond[[buildingConditionTab]][componentModernizationKey].real =
                            componentModernizationSteps.steps[e.target.value];
                        cond[[buildingConditionTab]][componentModernizationKey].display = e.target.value;
                        cond[[buildingConditionTab]][componentModernizationKey].weighted =
                            (componentModernizationSteps.steps[e.target.value] *
                                componentModernizationSteps.max_modernization_impact_pct) /
                            100;
                    });
                    setTotalModernization();
                }}
                sx={{
                    mt: "15px",
                    "& .MuiSlider-track": {
                        zIndex: -1,
                    },
                    "& .MuiSlider-rail": {
                        background: `linear-gradient(to right, #042D5C 0% ${
                            buildingConditionTab > 0
                                ? (buildingCondition[buildingConditionTab - 1][componentModernizationKey].display /
                                      (componentModernizationSteps.steps.length - 1)) *
                                  100
                                : 0
                        }%, ${theme.palette.secondary.main} ${
                            buildingConditionTab > 0
                                ? (buildingCondition[buildingConditionTab - 1][componentModernizationKey].display /
                                      (componentModernizationSteps.steps.length - 1)) *
                                  100
                                : 0
                        }% ${
                            (buildingCondition[buildingConditionTab][componentModernizationKey].display /
                                (componentModernizationSteps.steps.length - 1)) *
                            100
                        }%,
                        lightgrey ${
                            (buildingCondition[buildingConditionTab][componentModernizationKey].display /
                                (componentModernizationSteps.steps.length - 1)) *
                            100
                        }% 100%)`,
                        opacity: 1,
                        height: 6,
                        zIndex: 0,
                    },
                }}
                slots={{
                    mark: CustomSliderMarkComponent,
                }}
            />
        );
    };

    return (
        <>
            <DeleteConfirmationModal
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                confirmationText={
                    "Sind Sie sicher, dass Sie " +
                    'den Gebäudezustand "' +
                    buildingCondition[buildingConditionTab]?.year +
                    '"' +
                    " und alle zugehörigen Daten löschen möchten?"
                }
                deletionTargetFunction={deleteBuildingCondition}
            />
            <Box sx={{ mt: 3 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ConstructionIcon sx={{ fontSize: 30 }} />{" "}
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                        >
                            Gebäudezustand
                        </Typography>
                        {buildingCondition.length === 0 && (
                            <CustomTooltip title="Zu diesem Gebäude sind noch keine Zustandsdaten vorhanden.">
                                <ReportProblemTwoToneIcon sx={{ color: "#ff9e17", fontSize: 23, mt: 0.6, ml: 2 }} />
                            </CustomTooltip>
                        )}
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                        {fetchingBuildingConditionData && LoadingSpinner}
                        <Box sx={{ ml: 0 }} width="90%">
                            <Fab
                                variant="extended"
                                size="medium"
                                onClick={handleOpenAddConditionYear}
                                disabled={editModeBuildingConditions}
                                sx={{ zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                <AddIcon sx={{ mr: 1 }} />
                                Gebäudezustand hinzufügen
                            </Fab>
                            {buildingCondition.length > 0 && (
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    disabled={editModeBuildingConditions}
                                    onClick={(e) => {
                                        handleOpenDeleteConfirmationModal();
                                    }}
                                    sx={{ ml: 2, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                >
                                    <DeleteIcon />
                                    Gebäudezustand löschen
                                </Fab>
                            )}
                            <Modal
                                open={addConditionYearModalOpen}
                                onClose={handleCloseAddConditionYear}
                                aria-labelledby="add-year-modal"
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        if (addConditionYearValid) {
                                            addBuildingConditionTab();
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: 500,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Button
                                        sx={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "5px",
                                        }}
                                        onClick={() => handleCloseAddConditionYear()}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Für welches Jahr möchten Sie einen Gebäudezustand hinzufügen?
                                    </Typography>
                                    <NumberInput
                                        value={addConditionYearChoice}
                                        variant="outlined"
                                        error={!addConditionYearValid}
                                        onChange={(e) => {
                                            if (
                                                parseInt(e.target.value) < 1800 ||
                                                parseInt(e.target.value) > 2050 ||
                                                e.target.value.length !== 4 ||
                                                !buildingCondition.every((c) => c.year !== parseInt(e.target.value))
                                            ) {
                                                setAddConditionYearValid(false);
                                            } else {
                                                setAddConditionYearValid(true);
                                            }
                                            setAddConditionYearChoice(parseInt(e.target.value));
                                        }}
                                        sx={{ mt: 1 }}
                                        size="small"
                                        label="Jahr"
                                        type="number"
                                    />
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        disabled={!addConditionYearValid}
                                        onClick={(e) => addBuildingConditionTab()}
                                        color="secondary"
                                        sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1 }}
                                    >
                                        Speichern
                                    </Fab>
                                </Box>
                            </Modal>
                            {buildingCondition.length > 0 ? (
                                <>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        width="100%"
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter" && editModeBuildingConditions) {
                                                if (
                                                    !(
                                                        Object.keys(errorBuildingConditions).length > 0 &&
                                                        !Object.values(errorBuildingConditions).every(
                                                            (item) => item === false
                                                        )
                                                    )
                                                ) {
                                                    handleSave();
                                                }
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{ bgcolor: theme.palette.secondary.main, borderRadius: "5px" }}
                                            width="100%"
                                        >
                                            <Tabs
                                                TabIndicatorProps={{
                                                    children: <span className="MuiTabs-indicatorSpan" />,
                                                }}
                                                value={buildingConditionTab}
                                                onChange={handleBuildingConditionTab}
                                                variant="scrollable"
                                                scrollButtons={true}
                                                sx={{
                                                    borderColor: theme.palette.neutral.light,
                                                    height: "20px",
                                                    // bgcolor: theme.palette.primary.main,
                                                    mb: 1,
                                                    mt: 2,
                                                    "& .MuiTabs-scrollButtons.Mui-disabled": {
                                                        opacity: 0.3,
                                                    },
                                                    "& .Mui-selected": {
                                                        color: "primary",
                                                    },
                                                    "& .MuiTabs-indicator": {
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        backgroundColor: "transparent",
                                                        height: 4,
                                                        mb: "7px",
                                                    },
                                                    "& .MuiTabs-indicatorSpan": {
                                                        maxWidth: 50,
                                                        width: "100%",
                                                        backgroundColor: theme.palette.background.default,
                                                        height: "100%",
                                                    },
                                                }}
                                            >
                                                {buildingCondition.map((val, index) => {
                                                    return (
                                                        <Tab
                                                            key={"building-condition-tab-" + index}
                                                            disabled={editModeBuildingConditions}
                                                            label={val.year}
                                                            id={index}
                                                            sx={{ fontWeight: "bold", fontSize: "20px" }}
                                                        />
                                                    );
                                                })}
                                            </Tabs>
                                        </Box>
                                        <Box sx={{ width: "100%", paddingBottom: 1 }}>
                                            <div
                                                sx={{
                                                    marginLeft: "auto",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color={editModeBuildingConditions ? "default" : "secondary"}
                                                    onClick={() => {
                                                        handleEditMode();
                                                    }}
                                                    sx={{
                                                        marginRight: "1rem",
                                                        zIndex: 0,
                                                        boxShadow: 1,
                                                        borderRadius: 1,
                                                    }}
                                                >
                                                    {editModeBuildingConditions ? <></> : <EditIcon sx={{ mr: 1 }} />}
                                                    {editModeBuildingConditions ? "Abbrechen" : "Bearbeiten"}
                                                </Fab>
                                                {editModeBuildingConditions && (
                                                    <Fab
                                                        disabled={
                                                            (Object.keys(errorBuildingConditions).length > 0 &&
                                                                !Object.values(errorBuildingConditions).every(
                                                                    (item) => item === false
                                                                )) ||
                                                            (Object.keys(errorBuildingComponentParameters).length > 0 &&
                                                                checkObjectForTrue(errorBuildingComponentParameters))
                                                        }
                                                        variant="extended"
                                                        size="medium"
                                                        color={editModeBuildingConditions ? "secondary" : "default"}
                                                        onClick={() => handleSave()}
                                                        sx={{
                                                            marginRight: "1rem",
                                                            zIndex: 0,
                                                            boxShadow: 1,
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        <SaveIcon sx={{ mr: 1 }} />
                                                        Speichern
                                                    </Fab>
                                                )}
                                            </div>
                                        </Box>
                                        {/* Main input fields and result overview */}
                                        <Grid container>
                                            <Box sx={{ width: "32%", paddingBottom: 2 }}>
                                                <div>
                                                    <FloatInput
                                                        // variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                        label="Wohnfläche gesamt"
                                                        size="small"
                                                        value={buildingCondition[buildingConditionTab].living_space}
                                                        onChange={(val) =>
                                                            setBuildingCondition((cond) => {
                                                                cond[[buildingConditionTab]].living_space = val;
                                                            })
                                                        }
                                                        disabled={!editModeBuildingConditions}
                                                        sx={{ mt: 1, mr: "1ch", ml: 0, width: "23ch" }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    sx={{ mt: editModeBuildingConditions ? 0 : 0 }}
                                                                >
                                                                    {"m\u00B2"}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        validate={(val) => {
                                                            return true;
                                                        }}
                                                        error={errorBuildingConditions.living_space}
                                                        setError={(val) => {
                                                            setErrorBuildingConditions((err) => {
                                                                err.living_space = val;
                                                            });
                                                        }}
                                                    />
                                                    <IntegerInput
                                                        thousandsSeparator={true}
                                                        // variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                        label="Anzahl Wohnungen"
                                                        size="small"
                                                        value={buildingCondition[buildingConditionTab].apartments}
                                                        onChange={(val) =>
                                                            setBuildingCondition((cond) => {
                                                                cond[[buildingConditionTab]].apartments = val;
                                                            })
                                                        }
                                                        disabled={!editModeBuildingConditions}
                                                        sx={{
                                                            mt: 1,
                                                            width: "20ch",
                                                        }}
                                                        validate={(val) => {
                                                            if (!val) {
                                                                return false;
                                                            }
                                                            return true;
                                                        }}
                                                        error={errorBuildingConditions.apartments}
                                                        setError={(val) => {
                                                            setErrorBuildingConditions((err) => {
                                                                err.apartments = val;
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <BoundedIntegerInput
                                                        thousandsSeparator={true}
                                                        // variant={
                                                        //     editModeBuildingConditions ? "outlined" : "filled"
                                                        // }
                                                        label="Anzahl Whg. mit dez. Warmwasseraufbereitung"
                                                        size="small"
                                                        value={
                                                            buildingCondition[buildingConditionTab]
                                                                .apartments_with_cf_waterheating
                                                        }
                                                        onChange={(val) => {
                                                            setBuildingCondition((cond) => {
                                                                cond[
                                                                    [buildingConditionTab]
                                                                ].apartments_with_cf_waterheating = val;
                                                            });
                                                        }}
                                                        disabled={!editModeBuildingConditions}
                                                        sx={{
                                                            mt: 1,
                                                            mr: 1,
                                                            width: "44ch",
                                                        }}
                                                        validate={(val) => {
                                                            if (
                                                                parseInt(val) >
                                                                buildingCondition[buildingConditionTab].apartments.real
                                                            ) {
                                                                return false;
                                                            } else {
                                                                return true;
                                                            }
                                                        }}
                                                        error={errorBuildingConditions.apartments_with_cf_waterheating}
                                                        setError={(val) => {
                                                            setErrorBuildingConditions((err) => {
                                                                err.apartments_with_cf_waterheating = val;
                                                            });
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    sx={{
                                                                        mt: editModeBuildingConditions ? 0 : 0,
                                                                    }}
                                                                >
                                                                    <CustomTooltip title="Anzahl an Wohnungen, die primär eine dezentrale Warmwasseraufbereitung, wie einen Durchlauferhitzer, nutzen.">
                                                                        <InfoIcon color="neutral" />
                                                                    </CustomTooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <IntegerInput
                                                        thousandsSeparator={true}
                                                        // variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                        label="Anzahl Vollgeschosse"
                                                        size="small"
                                                        value={buildingCondition[buildingConditionTab].full_storeys}
                                                        onChange={(val) =>
                                                            setBuildingCondition((cond) => {
                                                                cond[[buildingConditionTab]].full_storeys = val;
                                                            })
                                                        }
                                                        disabled={!editModeBuildingConditions}
                                                        sx={{
                                                            mt: 1,
                                                            width: "20ch",
                                                        }}
                                                        validate={(val) => {
                                                            if (!val) {
                                                                return false;
                                                            }
                                                            return true;
                                                        }}
                                                        error={errorBuildingConditions.full_storeys}
                                                        setError={(val) => {
                                                            setErrorBuildingConditions((err) => {
                                                                err.full_storeys = val;
                                                            });
                                                        }}
                                                    />
                                                    <FloatInput
                                                        // variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                        label="Vollgeschosshöhe"
                                                        size="small"
                                                        value={
                                                            buildingCondition[buildingConditionTab].full_storey_height
                                                        }
                                                        onChange={(val) =>
                                                            setBuildingCondition((cond) => {
                                                                cond[[buildingConditionTab]].full_storey_height = val;
                                                            })
                                                        }
                                                        disabled={!editModeBuildingConditions}
                                                        sx={{ mt: 1, mr: 1, ml: "1ch", width: "23ch" }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    sx={{
                                                                        mt: editModeBuildingConditions ? 0 : 0,
                                                                    }}
                                                                >
                                                                    {"m"}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        validate={(val) => {
                                                            return true;
                                                        }}
                                                        error={errorBuildingConditions.full_storey_height}
                                                        setError={(val) => {
                                                            setErrorBuildingConditions((err) => {
                                                                err.full_storey_height = val;
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <BasicTextField
                                                        // variant={editModeBuildingConditions ? "outlined" : "filled"}
                                                        label="Anmerkung zum Gebäudezustand"
                                                        value={buildingCondition[buildingConditionTab].note}
                                                        onChange={(e) =>
                                                            setBuildingCondition((cond) => {
                                                                cond[[buildingConditionTab]].note = e.target.value;
                                                            })
                                                        }
                                                        disabled={!editModeBuildingConditions}
                                                        multiline
                                                        rows={2}
                                                        sx={{ mt: 1, mr: 1, ml: 0, width: "44ch" }}
                                                    />
                                                </div>
                                            </Box>
                                            {/* Building Component Areas Table */}
                                            <Box
                                                sx={{
                                                    width: "37ch",
                                                    m: 1,
                                                    height: "162px",
                                                    border: "lightgray",
                                                    borderStyle: "solid",
                                                    borderWidth: "1px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor:
                                                            theme.palette.mode === "dark"
                                                                ? "rgba(255, 255, 255, .05)"
                                                                : "rgba(0, 0, 0, .03)",
                                                    }}
                                                >
                                                    <Typography sx={{ padding: 1, fontSize: 18, fontWeight: "bold" }}>
                                                        Bauteilflächen
                                                    </Typography>
                                                </Box>
                                                <Grid container>
                                                    {/* Topleft */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Fassade
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {getComponentAreaSum("hull")} m²
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="vertical" flexItem />
                                                    {/* Topright */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Dach
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {getComponentAreaSum("roof")} m²
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="horizontal" flexItem sx={{ width: "100%" }} />
                                                    {/* Bottomleft */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Fenster
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {getComponentAreaSum("windows")} m²
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="vertical" flexItem />
                                                    {/* Bottomright */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Keller(-decke)
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {getComponentAreaSum("cellarroof")} m²
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                            {/* Building Modernization Costs UserInput Modal */}
                                            <UserInputModernizationCostsModal
                                                currentUserInputCosts={
                                                    buildingCondition[buildingConditionTab].modernization_costs
                                                        .userinput
                                                }
                                            />
                                            {/* Building Modernization Costs Table */}
                                            <Box
                                                sx={{
                                                    width: "37ch",
                                                    m: 1,
                                                    height: "223px",
                                                    border: "lightgray",
                                                    borderStyle: "solid",
                                                    borderWidth: "1px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor:
                                                            theme.palette.mode === "dark"
                                                                ? "rgba(255, 255, 255, .05)"
                                                                : "rgba(0, 0, 0, .03)",
                                                    }}
                                                >
                                                    <Box sx={{ width: "78%" }}>
                                                        <Typography
                                                            sx={{
                                                                padding: 1,
                                                                fontSize: 18,
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Modernisierungskosten
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ width: "11%" }}>
                                                        <Fab
                                                            size="small"
                                                            onClick={handleOpenUserInputModernizationCostsModal}
                                                            sx={{
                                                                mt: 0.5,
                                                                ml: -1,
                                                                height: "3.0ch",
                                                                width: "3.6ch",
                                                                borderRadius: 1,
                                                                boxShadow: 0,
                                                                border: "1px solid lightgrey",
                                                                backgroundColor: "white",
                                                                zIndex: 0,
                                                            }}
                                                        >
                                                            <CustomTooltip
                                                                title={
                                                                    <>
                                                                        Wenn Sie Ihre tatsächlich entstandenen
                                                                        Modernisierungskosten festhalten möchten,
                                                                        klicken Sie hier.
                                                                    </>
                                                                }
                                                            >
                                                                <SaveAsIcon
                                                                    sx={{
                                                                        ml: 0.2,
                                                                        color: theme.palette.secondary.main,
                                                                        fontSize: 22,
                                                                    }}
                                                                />
                                                            </CustomTooltip>
                                                        </Fab>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: "11%",
                                                        }}
                                                    >
                                                        <ToggleButton
                                                            value="check"
                                                            selected={showEstimatedCosts}
                                                            onChange={() => {
                                                                setShowEstimatedCosts(!showEstimatedCosts);
                                                            }}
                                                            sx={{
                                                                mt: 0.5,
                                                                ml: 0,
                                                                height: "4.4ch",
                                                                width: "3.6ch",
                                                                backgroundColor: "white",
                                                            }}
                                                        >
                                                            {showEstimatedCosts ? (
                                                                <CustomTooltip
                                                                    position="top"
                                                                    title={
                                                                        <>
                                                                            Es handelt sich um eine Schätzung basierend
                                                                            auf den vorhandenen Angaben zum Gebäude, zum
                                                                            vorherigen und zu diesem Gebäudezustand.
                                                                            <br />
                                                                            <br />
                                                                            Klicken Sie hier, um stattdessen Ihre
                                                                            angegebenen Kosten anzuzeigen.
                                                                        </>
                                                                    }
                                                                >
                                                                    <InsightsTwoToneIcon
                                                                        sx={{
                                                                            ml: 0.1,
                                                                            color: "#042D5A",
                                                                            fontSize: 24,
                                                                        }}
                                                                    />
                                                                </CustomTooltip>
                                                            ) : (
                                                                <CustomTooltip
                                                                    position="top"
                                                                    title={
                                                                        <>
                                                                            Es handelt sich um Ihre angegebenen Kosten
                                                                            (falls vorhanden).
                                                                            <br />
                                                                            <br />
                                                                            Klicken Sie hier, um stattdessen die
                                                                            geschätzten Kosten anzuzeigen.
                                                                        </>
                                                                    }
                                                                >
                                                                    <EditNoteIcon
                                                                        sx={{
                                                                            ml: 0.1,
                                                                            color: "#042D5A",
                                                                            fontSize: 24,
                                                                        }}
                                                                    />
                                                                </CustomTooltip>
                                                            )}
                                                        </ToggleButton>
                                                    </Box>
                                                </Grid>
                                                <Grid container>
                                                    {/* Topleft */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Fassade
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {!showEstimatedCosts
                                                                ? (buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.userinput
                                                                      ? buildingCondition[buildingConditionTab]
                                                                            .modernization_costs.userinput.cost_hull
                                                                            .display
                                                                      : "0") + "€"
                                                                : buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated
                                                                ? buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated.cost_hull.display +
                                                                  "€"
                                                                : "N/V"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="vertical" flexItem />
                                                    {/* Topright */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Dach
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {!showEstimatedCosts
                                                                ? (buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.userinput
                                                                      ? buildingCondition[buildingConditionTab]
                                                                            .modernization_costs.userinput.cost_roof
                                                                            .display
                                                                      : "0") + "€"
                                                                : buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated
                                                                ? buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated.cost_roof.display +
                                                                  "€"
                                                                : "N/V"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="horizontal" flexItem sx={{ width: "100%" }} />
                                                    {/* Bottomleft */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Fenster
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {!showEstimatedCosts
                                                                ? (buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.userinput
                                                                      ? buildingCondition[buildingConditionTab]
                                                                            .modernization_costs.userinput.cost_windows
                                                                            .display
                                                                      : "0") + "€"
                                                                : buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated
                                                                ? buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated.cost_windows
                                                                      .display + "€"
                                                                : "N/V"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="vertical" flexItem />
                                                    {/* Bottomright */}
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                                                            Keller(-decke)
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 13 }}>
                                                            {!showEstimatedCosts
                                                                ? (buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.userinput
                                                                      ? buildingCondition[buildingConditionTab]
                                                                            .modernization_costs.userinput
                                                                            .cost_cellarroof.display
                                                                      : "0") + "€"
                                                                : buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated
                                                                ? buildingCondition[buildingConditionTab]
                                                                      .modernization_costs.estimated.cost_cellarroof
                                                                      .display + "€"
                                                                : "N/V"}
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="horizontal" flexItem sx={{ width: "100%" }} />
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 15,
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Gesamt
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            <u>
                                                                {!showEstimatedCosts
                                                                    ? (buildingCondition[buildingConditionTab]
                                                                          .modernization_costs.userinput
                                                                          ? buildingCondition[buildingConditionTab]
                                                                                .modernization_costs.userinput
                                                                                .total_cost.display
                                                                          : "0") + "€"
                                                                    : buildingCondition[buildingConditionTab]
                                                                          .modernization_costs.estimated
                                                                    ? buildingCondition[buildingConditionTab]
                                                                          .modernization_costs.estimated.total_cost
                                                                          .display + "€"
                                                                    : "N/V"}
                                                            </u>
                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Box
                                                        sx={{
                                                            width: "18ch",
                                                            padding: 1,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 15,
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            Gesamt je m²
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            <u>
                                                                {!showEstimatedCosts
                                                                    ? (buildingCondition[buildingConditionTab]
                                                                          .modernization_costs.userinput
                                                                          ? createGermanNumberString(
                                                                                buildingCondition[buildingConditionTab]
                                                                                    .modernization_costs.userinput
                                                                                    .total_cost.real /
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].living_space.real,
                                                                                true,
                                                                                2
                                                                            )
                                                                          : 0) + "€/m²"
                                                                    : buildingCondition[buildingConditionTab]
                                                                          .modernization_costs.estimated
                                                                    ? createGermanNumberString(
                                                                          buildingCondition[buildingConditionTab]
                                                                              .modernization_costs.estimated.total_cost
                                                                              .real /
                                                                              buildingCondition[buildingConditionTab]
                                                                                  .living_space.real,
                                                                          true,
                                                                          2
                                                                      ) + "€/m²"
                                                                    : "N/V"}
                                                            </u>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <CustomAccordion
                                            sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)" }}
                                            expanded={nestedAccordionExpanded === 1}
                                            onChange={handleNestedAccordionChange(1)}
                                        >
                                            <CustomAccordionSummary>
                                                <Typography
                                                    sx={{
                                                        ml: 0,
                                                        fontSize: 15,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Modernisierungszustand
                                                    <CustomTooltip title='Der Modernisierungszustand ist auf das Baujahr des Gebäudes normiert. Die Angabe "0" für den Modernisierungszustand eines Bauteils entspricht also etwa den gängigen Komponenten, die im angegebenen Baujahr für diesen Gebäudetyp verwendet wurden.'>
                                                        <InfoIcon sx={{ mb: "-5px", ml: "10px" }} color="neutral" />
                                                    </CustomTooltip>
                                                </Typography>
                                            </CustomAccordionSummary>
                                            <CustomAccordionDetails>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell width="11%" align="left">
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        Bauteil
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell width="20%" align="center">
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        Zustand
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell width="45%" align="left">
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        Referenz
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell width="10%" align="left">
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 16,
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        Kostenschätzung
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {/* ---------------------- Fassade ---------------------- */}
                                                            <TableRow>
                                                                <SmallTableCell
                                                                    sx={{
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    Fassade
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {ModernizationSlider({
                                                                        previousModernizationValue:
                                                                            buildingConditionTab > 0
                                                                                ? buildingCondition[
                                                                                      buildingConditionTab - 1
                                                                                  ].component_modernization_hull.display
                                                                                : 0,
                                                                        componentModernizationKey:
                                                                            "component_modernization_hull",
                                                                        componentModernizationSteps:
                                                                            ComponentModernizationSteps.hull,
                                                                        modernizationSliderMarks:
                                                                            modernizationSliderMarks.hull,
                                                                    })}
                                                                </SmallTableCell>

                                                                <SmallTableCell>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        {ComponentModernizationReferences
                                                                            ? getModernizationReferenceDescription(
                                                                                  buildingCondition[
                                                                                      buildingConditionTab
                                                                                  ].component_modernization_hull
                                                                                      .weighted,
                                                                                  ComponentModernizationReferences.hull
                                                                              )
                                                                            : ""}
                                                                    </Typography>
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {editModeBuildingConditions ||
                                                                    !buildingCondition[buildingConditionTab]
                                                                        .modernization_costs.estimated ? (
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            N/V
                                                                        </Typography>
                                                                    ) : (
                                                                        <>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_hull.display
                                                                                }
                                                                                € gesamt bei
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_per_sqm_hull.display
                                                                                }
                                                                                €/m²
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                </SmallTableCell>
                                                            </TableRow>
                                                            {/* ---------------------- Fenster ---------------------- */}
                                                            <TableRow>
                                                                <SmallTableCell
                                                                    sx={{
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    Fenster
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {ModernizationSlider({
                                                                        previousModernizationValue:
                                                                            buildingConditionTab > 0
                                                                                ? buildingCondition[
                                                                                      buildingConditionTab - 1
                                                                                  ].component_modernization_windows
                                                                                      .display
                                                                                : 0,
                                                                        componentModernizationKey:
                                                                            "component_modernization_windows",
                                                                        componentModernizationSteps:
                                                                            ComponentModernizationSteps.windows,
                                                                        modernizationSliderMarks:
                                                                            modernizationSliderMarks.windows,
                                                                    })}
                                                                </SmallTableCell>

                                                                <SmallTableCell>
                                                                    <Typography sx={{ fontSize: 12 }}>
                                                                        {ComponentModernizationReferences
                                                                            ? getModernizationReferenceDescription(
                                                                                  buildingCondition[
                                                                                      buildingConditionTab
                                                                                  ].component_modernization_windows
                                                                                      .weighted,
                                                                                  ComponentModernizationReferences.windows
                                                                              )
                                                                            : ""}
                                                                    </Typography>
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {editModeBuildingConditions ||
                                                                    !buildingCondition[buildingConditionTab]
                                                                        .modernization_costs.estimated ? (
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            N/V
                                                                        </Typography>
                                                                    ) : (
                                                                        <>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_windows.display
                                                                                }
                                                                                € gesamt bei
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_per_sqm_windows.display
                                                                                }
                                                                                €/m²
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                </SmallTableCell>
                                                            </TableRow>
                                                            {/* ---------------------- Dach ---------------------- */}
                                                            <TableRow>
                                                                <SmallTableCell
                                                                    sx={{
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    Dach
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {ModernizationSlider({
                                                                        previousModernizationValue:
                                                                            buildingConditionTab > 0
                                                                                ? buildingCondition[
                                                                                      buildingConditionTab - 1
                                                                                  ].component_modernization_roof.display
                                                                                : 0,
                                                                        componentModernizationKey:
                                                                            "component_modernization_roof",
                                                                        componentModernizationSteps:
                                                                            ComponentModernizationSteps.roof,
                                                                        modernizationSliderMarks:
                                                                            modernizationSliderMarks.roof,
                                                                    })}
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    <Typography sx={{ fontSize: 12 }}>
                                                                        {ComponentModernizationReferences
                                                                            ? getModernizationReferenceDescription(
                                                                                  buildingCondition[
                                                                                      buildingConditionTab
                                                                                  ].component_modernization_roof
                                                                                      .weighted,
                                                                                  ComponentModernizationReferences.roof
                                                                              )
                                                                            : ""}
                                                                    </Typography>
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {editModeBuildingConditions ||
                                                                    !buildingCondition[buildingConditionTab]
                                                                        .modernization_costs.estimated ? (
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            N/V
                                                                        </Typography>
                                                                    ) : (
                                                                        <>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_roof.display
                                                                                }
                                                                                € gesamt bei
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_per_sqm_roof.display
                                                                                }
                                                                                €/m²
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                </SmallTableCell>
                                                            </TableRow>
                                                            {/* ---------------------- Keller(-decke) ---------------------- */}
                                                            <TableRow>
                                                                <SmallTableCell
                                                                    sx={{
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    Keller(-decke)
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {ModernizationSlider({
                                                                        previousModernizationValue:
                                                                            buildingConditionTab > 0
                                                                                ? buildingCondition[
                                                                                      buildingConditionTab - 1
                                                                                  ].component_modernization_cellarroof
                                                                                      .display
                                                                                : 0,
                                                                        componentModernizationKey:
                                                                            "component_modernization_cellarroof",
                                                                        componentModernizationSteps:
                                                                            ComponentModernizationSteps.cellarroof,
                                                                        modernizationSliderMarks:
                                                                            modernizationSliderMarks.cellarroof,
                                                                    })}
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    <Typography sx={{ fontSize: 12 }}>
                                                                        {ComponentModernizationReferences
                                                                            ? getModernizationReferenceDescription(
                                                                                  buildingCondition[
                                                                                      buildingConditionTab
                                                                                  ].component_modernization_cellarroof
                                                                                      .weighted,
                                                                                  ComponentModernizationReferences.cellarroof
                                                                              )
                                                                            : ""}
                                                                    </Typography>
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {editModeBuildingConditions ||
                                                                    !buildingCondition[buildingConditionTab]
                                                                        .modernization_costs.estimated ? (
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 14,
                                                                            }}
                                                                        >
                                                                            N/V
                                                                        </Typography>
                                                                    ) : (
                                                                        <>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_cellarroof.display
                                                                                }
                                                                                € gesamt bei
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: 14,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    buildingCondition[
                                                                                        buildingConditionTab
                                                                                    ].modernization_costs.estimated
                                                                                        ?.cost_per_sqm_cellarroof
                                                                                        .display
                                                                                }
                                                                                €/m²
                                                                            </Typography>
                                                                        </>
                                                                    )}
                                                                </SmallTableCell>
                                                            </TableRow>
                                                            {/* ---------------------- Lüftung ---------------------- */}
                                                            <TableRow>
                                                                <SmallTableCell
                                                                    sx={{
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    Lüftung
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    {ModernizationSlider({
                                                                        previousModernizationValue:
                                                                            buildingConditionTab > 0
                                                                                ? buildingCondition[
                                                                                      buildingConditionTab - 1
                                                                                  ].component_modernization_ventilation
                                                                                      .display
                                                                                : 0,
                                                                        componentModernizationKey:
                                                                            "component_modernization_ventilation",
                                                                        componentModernizationSteps:
                                                                            ComponentModernizationSteps.ventilation,
                                                                        modernizationSliderMarks:
                                                                            modernizationSliderMarks.ventilation,
                                                                    })}
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    <Typography sx={{ fontSize: 12 }}>
                                                                        {ComponentModernizationReferences
                                                                            ? getModernizationReferenceDescription(
                                                                                  buildingCondition[
                                                                                      buildingConditionTab
                                                                                  ].component_modernization_ventilation
                                                                                      .weighted,
                                                                                  ComponentModernizationReferences.ventilation,
                                                                                  false
                                                                              )
                                                                            : ""}
                                                                    </Typography>
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 14,
                                                                        }}
                                                                    >
                                                                        -
                                                                    </Typography>
                                                                </SmallTableCell>
                                                            </TableRow>
                                                            {/* Gesamt */}
                                                            <TableRow>
                                                                <SmallTableCell
                                                                    sx={{
                                                                        fontSize: 16,
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Gesamt
                                                                </SmallTableCell>
                                                                <SmallTableCell>
                                                                    <Slider
                                                                        aria-label="Prozent"
                                                                        defaultValue={0}
                                                                        valueLabelDisplay="auto"
                                                                        step={1}
                                                                        min={0}
                                                                        max={100}
                                                                        value={
                                                                            buildingCondition[buildingConditionTab]
                                                                                .total_degree_of_modernization.real
                                                                                ? buildingCondition[
                                                                                      buildingConditionTab
                                                                                  ].total_degree_of_modernization.real
                                                                                : 0
                                                                        }
                                                                        sx={{ mt: 2, mb: 2 }}
                                                                    />
                                                                </SmallTableCell>
                                                                <SmallTableCell />
                                                                <SmallTableCell />
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {buildingCondition[buildingConditionTab]
                                                    .statistical_consumption_roomheating > 0 &&
                                                energyEfficiencyClasses ? (
                                                    <Grid container mt="2ch" ml="13px">
                                                        <Box width="41%">
                                                            <ConsumptionScale
                                                                consumptionMarker={
                                                                    buildingCondition[buildingConditionTab]
                                                                        .statistical_consumption_roomheating +
                                                                    buildingCondition[buildingConditionTab]
                                                                        .statistical_consumption_waterheating
                                                                }
                                                                energyEfficiencyClasses={energyEfficiencyClasses}
                                                                heightScales={0.9}
                                                            />
                                                        </Box>

                                                        <Box width="2%" ml="13px" mt="15px">
                                                            <InfoIcon color="neutral" />
                                                        </Box>
                                                        <Box width="35%">
                                                            <Typography mt="10px">
                                                                Raumwärme + Warmwasser, bezogen auf den Energieverbrauch
                                                                ab Wärmeerzeuger
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                            </CustomAccordionDetails>
                                        </CustomAccordion>
                                        <CustomAccordion
                                            sx={{ borderTop: "1px solid rgba(0, 0, 0, .125)" }}
                                            expanded={nestedAccordionExpanded === 2}
                                            onChange={handleNestedAccordionChange(2)}
                                        >
                                            <CustomAccordionSummary>
                                                <Typography
                                                    sx={{
                                                        ml: 0,
                                                        fontSize: 15,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Bauteilflächenberechnung
                                                    {/* <CustomTooltip title=''>
                                                                <InfoIcon
                                                                    sx={{ mb: "-3px", ml: "10px" }}
                                                                    color="neutral"
                                                                />
                                                            </CustomTooltip> */}
                                                </Typography>
                                            </CustomAccordionSummary>
                                            <CustomAccordionDetails>
                                                <Box>
                                                    {buildingCondition && (
                                                        <BuildingComponentParameterAccordion
                                                            buildingCondition={buildingCondition}
                                                            setBuildingCondition={setBuildingCondition}
                                                            buildingConditionTab={buildingConditionTab}
                                                            errorBuildingComponentParameters={
                                                                errorBuildingComponentParameters
                                                            }
                                                            setErrorBuildingComponentParameters={
                                                                setErrorBuildingComponentParameters
                                                            }
                                                            editModeBuildingConditions={editModeBuildingConditions}
                                                        />
                                                    )}
                                                </Box>
                                            </CustomAccordionDetails>
                                        </CustomAccordion>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: "200px",
                                        width: "100%",
                                    }}
                                >
                                    <Alert severity="info" sx={{ textAlign: "left", height: "8ch", fontSize: 14 }}>
                                        <strong>Es sind keine Gebäudezustände verfügbar.</strong>
                                        <br />
                                        Bitte fügen Sie einen Gebäudezustand über "Gebäudezustand hinzufügen" hinzu.
                                        <br />
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};
