/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useImmer } from "use-immer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    MenuItem,
    Box,
    Grid,
    InputLabel,
    FormControl,
    Divider,
    Fab,
    CircularProgress,
    InputAdornment,
    Tooltip,
    Chip,
    Checkbox,
    ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import api_client from "../../services/http-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { addDisplayNumbersInJson, cleanNumbersInJson } from "../../utils/formatting";
import { tooltipClasses } from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastContext from "../../contexts/ToastContext";
import PDFUploadDropzone from "../../components/FileUploadDropzone";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";
import "dayjs/locale/de";
import dayjs from "dayjs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import NotificationImportantTwoToneIcon from "@mui/icons-material/NotificationImportantTwoTone";
import { BasicSelectField, FloatInput } from "../../components/InputFields";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "220px",
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    })
);

const LoadingSpinner = (
    <div
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            backdropFilter: "blur(3px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
        }}
    >
        <CircularProgress />
    </div>
);

export const BuildingEnergyCertificateAccordion = ({ currentBuilding, buildings, setEnergyCertificateValue }) => {
    const theme = useTheme();

    // fetch energy certificate data from backend
    const emptyEnergyCertificate = {
        file_url: null,
        file_name: null,
        file_upload_date: null,
        issued_date: null,
        valid_until_date: null,
        value: { display: "", real: null },
        usable_area: { display: "", real: null },
        type: "VERBRAUCH",
        modified_date: null,
        related_buildings: [currentBuilding.id],
    };
    const [energyCertificate, setEnergyCertificate] = useImmer(emptyEnergyCertificate);
    const [energyCertificateLoading, setEnergyCertificateLoading] = useState(false);
    const { addToastMessage } = useContext(ToastContext);

    const fetchEnergyCertificate = async () => {
        setEnergyCertificateLoading(true);
        await api_client
            .get(`/buildings/${currentBuilding.id}/energy-certificate/`)
            .then((response) => {
                if (Object.keys(response.data.data.certificate).length === 0) {
                    setEnergyCertificate(emptyEnergyCertificate);
                } else {
                    setEnergyCertificate({
                        ...addDisplayNumbersInJson(response.data.data.certificate),
                        issued_date: response.data.data.certificate.issued_date
                            ? dayjs(response.data.data.certificate.issued_date)
                            : null,
                        valid_until_date: response.data.data.certificate.valid_until_date
                            ? dayjs(response.data.data.certificate.valid_until_date)
                            : null,
                        related_buildings: response.data.data.certificate.related_buildings,
                    });
                }
                setEnergyCertificateValue(response.data.data.building_certificate_value);
                setEnergyCertificateLoading(false);
            })
            .catch(() => {
                addToastMessage("Fehler beim Laden des Energieausweises.", "error", true);
                setEnergyCertificateLoading(false);
            });
    };

    useEffect(() => {
        fetchEnergyCertificate();
    }, [currentBuilding.id]);

    const UploadDropzone = () => {
        const handleFileUpload = (acceptedFiles) => {
            // send file to backend
            setEnergyCertificateLoading(true);
            const formData = new FormData();
            formData.append("file", acceptedFiles[0]);
            api_client
                .post(`/buildings/${currentBuilding.id}/energy-certificate/upload-file/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    setEnergyCertificate({
                        ...addDisplayNumbersInJson(response.data.data.certificate),
                        issued_date: response.data.data.certificate.issued_date
                            ? dayjs(response.data.data.certificate.issued_date)
                            : null,
                        valid_until_date: response.data.data.certificate.valid_until_date
                            ? dayjs(response.data.data.certificate.valid_until_date)
                            : null,
                        related_buildings: response.data.data.certificate.related_buildings,
                    });
                    setEnergyCertificateValue(response.data.data.building_certificate_value);
                    setEnergyCertificateLoading(false);
                    addToastMessage("Energieausweis erfolgreich hochgeladen.", "success", true);
                })
                .catch((error) => {
                    setEnergyCertificateLoading(false);
                    addToastMessage("Fehler beim Hochladen des Energieausweises.", "error", true);
                });
        };

        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    backdropFilter: "blur(3px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1,
                }}
            >
                <PDFUploadDropzone onFileUpload={handleFileUpload} />
            </Box>
        );
    };

    const [editModeBuildingEnergyCertificate, setEditModeBuildingEnergyCertificate] = useState(false);
    const [errorBuildingEnergyCertificate, setErrorBuildingEnergyCertificate] = useImmer({});

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingEnergyCertificate === true) {
            fetchEnergyCertificate();
            setEditModeBuildingEnergyCertificate(false);
        } else {
            setEditModeBuildingEnergyCertificate(true);
        }
        setErrorBuildingEnergyCertificate({});
    };

    // handle the saving after editing
    const handleSave = async () => {
        setEditModeBuildingEnergyCertificate((prevEditMode) => !prevEditMode);
        // post building-details to backend
        setEnergyCertificateLoading(true);
        api_client
            .post(`/buildings/${currentBuilding.id}/energy-certificate/`, {
                ...cleanNumbersInJson(energyCertificate),
                issued_date: energyCertificate.issued_date ? energyCertificate.issued_date.format("YYYY-MM-DD") : null,
                valid_until_date: energyCertificate.valid_until_date
                    ? energyCertificate.valid_until_date.format("YYYY-MM-DD")
                    : null,
                related_buildings: energyCertificate.related_buildings,
            })
            .then((response) => {
                if (Object.keys(response.data.data.certificate).length === 0) {
                    setEnergyCertificate(emptyEnergyCertificate);
                } else {
                    setEnergyCertificate({
                        ...addDisplayNumbersInJson(response.data.data.certificate),
                        issued_date: response.data.data.certificate.issued_date
                            ? dayjs(response.data.data.certificate.issued_date)
                            : null,
                        valid_until_date: response.data.data.certificate.valid_until_date
                            ? dayjs(response.data.data.certificate.valid_until_date)
                            : null,
                        related_buildings: response.data.data.certificate.related_buildings,
                    });
                }
                setEnergyCertificateValue(response.data.data.building_certificate_value);
                setEnergyCertificateLoading(false);
                if (response.data.data.related_buildings_not_updated.length > 0) {
                    addToastMessage(
                        <p>
                            Einige Gebäude konnten nicht aktualisiert werden, da sie bereits einen Energieausweis
                            zugewiesen haben. <br />
                            Bitte löschen Sie den Energieausweis zuerst von diesen Gebäuden und versuchen Sie es erneut.
                            <br />
                            Betroffene Gebäude: <br />
                            <b>
                                {response.data.data.related_buildings_not_updated
                                    .map((item) => item.address)
                                    .join(", ")}
                            </b>
                        </p>,
                        "warning",
                        true,
                        20000
                    );
                } else {
                    addToastMessage("Energieausweis erfolgreich angepasst.", "success", true);
                }
            })
            .catch((error) => {
                setEnergyCertificateLoading(false);
                addToastMessage("Fehler beim Anpassen des Energieausweises.", "error", true);
            });
    };

    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    const deleteFile = async () => {
        handleCloseDeleteConfirmationModal();
        setEnergyCertificateLoading(true);
        await api_client
            .delete(`/buildings/${currentBuilding.id}/energy-certificate/delete-file/`)
            .then((response) => {
                setEnergyCertificate({
                    ...addDisplayNumbersInJson(response.data.data.certificate),
                    issued_date: response.data.data.certificate.issued_date
                        ? dayjs(response.data.data.certificate.issued_date)
                        : null,
                    valid_until_date: response.data.data.certificate.valid_until_date
                        ? dayjs(response.data.data.certificate.valid_until_date)
                        : null,
                    related_buildings: response.data.data.certificate.related_buildings,
                });
                setEnergyCertificateValue(response.data.data.building_certificate_value);
                setEnergyCertificateLoading(false);
                addToastMessage("Energieausweis erfolgreich gelöscht.", "success", true);
            })
            .catch((error) => {
                setEnergyCertificateLoading(false);
                addToastMessage("Fehler beim Löschen des Energieausweises.", "error", true);
            });
    };

    const EnergyCertificateDisplay = (
        <>
            <DeleteConfirmationModal
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                confirmationText={
                    <>
                        Sind Sie sicher, dass Sie die Datei
                        <br />"<i>{energyCertificate.file_name}</i>"<br />
                        löschen möchten?
                        <br />
                        <br />
                        <b>Achtung: Die Datei kann nicht wiederhergestellt werden.</b>
                        <br />
                        {energyCertificate.related_buildings.length > 1 && (
                            <b>
                                Achtung: Der Energieausweis ist mehreren Gebäuden zugewiesen. Die Datei wird für jedes
                                dieser Gebäude gelöscht!
                            </b>
                        )}
                    </>
                }
                deletionTargetFunction={deleteFile}
            />
            <Box sx={{ width: "100%" }}>
                <Divider sx={{ mt: 2, mb: 1, width: 600 }}>Dateiupload</Divider>
                <Box>
                    {energyCertificate.file_url ? (
                        <Box>
                            <Grid container>
                                {/* File download button */}
                                <Link to={energyCertificate.file_url} target="_blank" download>
                                    <Fab
                                        variant="extended"
                                        size="small"
                                        color="primary"
                                        sx={{ ml: 1, mr: 2, boxShadow: 1, borderRadius: 1 }}
                                    >
                                        <OpenInNewIcon sx={{ mr: 1 }} />
                                        {energyCertificate.file_name}
                                    </Fab>
                                </Link>
                                {/* File delete button */}
                                <Fab
                                    variant="extended"
                                    size="small"
                                    sx={{ ml: 1, mr: 2, boxShadow: 1, borderRadius: 1 }}
                                    onClick={handleOpenDeleteConfirmationModal}
                                >
                                    <DeleteIcon sx={{ mr: 1 }} />
                                    Datei löschen
                                </Fab>
                                {/* File upload date */}
                                <Box sx={{ width: "100%", mt: 2 }}>
                                    <Typography variant="body1" sx={{ minWidth: "10ch", ml: 1 }}>
                                        Hochgeladen am:{""}
                                        <br />
                                        {dayjs(energyCertificate.file_upload_date).format("DD.MM.YYYY")}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>
                    ) : (
                        /* Energieausweis Datei upload button */
                        <Box
                            sx={{
                                width: "600px",
                                height: "120px",
                                alignContent: "center",
                                display: "flex",
                            }}
                        >
                            <UploadDropzone />
                        </Box>
                    )}
                </Box>
                <Divider sx={{ mt: 2, width: 600 }}>Angaben</Divider>
                <Grid container sx={{ width: 700 }}>
                    {/* Energieausweis Typ */}
                    <Box sx={{ mb: 1, width: "100%" }}>
                        <BasicSelectField
                            FormControlProps={{}}
                            InputLabelProps={{
                                id: "energy-certificate-type-label",
                                sx: {
                                    mt: !energyCertificate.type ? -0.5 : 0,
                                },
                            }}
                            label="Energieausweis-Typ"
                            labelId="energy-certificate-type-label"
                            id="energy-certificate-type"
                            value={energyCertificate.type}
                            onChange={(e) =>
                                setEnergyCertificate((certificate) => {
                                    certificate.type = e.target.value;
                                })
                            }
                            disabled={!editModeBuildingEnergyCertificate}
                            sx={{ width: "200px" }}
                            size="small"
                            defaultValue="VERBRAUCH"
                            menuItems={[
                                { name: "Bedarfsausweis", value: "BEDARF" },
                                { name: "Verbrauchsausweis", value: "VERBRAUCH" },
                            ]}
                        />
                    </Box>
                    {/* Energieausweis Ausstellungsdatum Datepicker */}
                    <Box sx={{ mb: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DatePicker
                                label="Ausstellungsdatum"
                                value={energyCertificate.issued_date ? dayjs(energyCertificate.issued_date) : null}
                                onChange={(value) =>
                                    setEnergyCertificate((certificate) => {
                                        certificate.issued_date = value;
                                        if (
                                            value &&
                                            value.isValid() &&
                                            value.year() > 1800 &&
                                            !certificate.valid_until_date
                                        ) {
                                            certificate.valid_until_date = value.add(10, "year"); // set valid_until_date to 10 years after issued_date
                                        }
                                    })
                                }
                                sx={{
                                    width: "20ch",
                                }}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        // variant: editModeBuildingEnergyCertificate ? "outlined" : "filled",
                                        sx: {
                                            backgroundColor: !editModeBuildingEnergyCertificate ? "#f3f3f3" : "inherit",
                                        },
                                    },
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                    field: { clearable: true },
                                }}
                                disabled={!editModeBuildingEnergyCertificate}
                            />
                        </LocalizationProvider>
                    </Box>
                    {/* Energieausweis Gültig bis Datepicker */}
                    <Box sx={{ mb: 1, ml: 1, width: "50%" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DatePicker
                                label="Gültig bis"
                                value={
                                    energyCertificate.valid_until_date
                                        ? dayjs(energyCertificate.valid_until_date)
                                        : null
                                }
                                onChange={(value) =>
                                    setEnergyCertificate((certificate) => {
                                        certificate.valid_until_date = value;
                                        if (
                                            value &&
                                            value.isValid() &&
                                            value.year() > 1800 &&
                                            !certificate.issued_date
                                        ) {
                                            certificate.issued_date = value.add(-10, "year"); // set issued_date to 10 years before valid_until_date
                                        }
                                    })
                                }
                                sx={{
                                    width: "20ch",
                                }}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        // variant: editModeBuildingEnergyCertificate ? "outlined" : "filled",
                                        sx: {
                                            backgroundColor: !editModeBuildingEnergyCertificate ? "#f3f3f3" : "inherit",
                                        },
                                    },
                                    actionBar: {
                                        actions: ["clear"],
                                    },
                                    field: { clearable: true },
                                }}
                                clearButton
                                disabled={!editModeBuildingEnergyCertificate}
                            />
                        </LocalizationProvider>
                    </Box>
                    {/* Energieausweis Verbrauch/Bedarfs-Kennwert */}
                    <Box sx={{ mb: 1 }}>
                        <FloatInput
                            // variant={editModeBuildingEnergyCertificate ? "outlined" : "filled"}
                            label={
                                energyCertificate.type && energyCertificate.type === "BEDARF"
                                    ? "Endenergiebedarf"
                                    : "Endenergieverbrauch"
                            }
                            value={energyCertificate.value}
                            onChange={(value) =>
                                setEnergyCertificate((certificate) => {
                                    certificate.value = value;
                                })
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kWh/m²a</InputAdornment>,
                            }}
                            disabled={!editModeBuildingEnergyCertificate}
                            size="small"
                            validate={(val) => {
                                return true;
                            }}
                            error={errorBuildingEnergyCertificate.value}
                            setError={(val) => {
                                setErrorBuildingEnergyCertificate((err) => {
                                    err.value = val;
                                });
                            }}
                        />
                    </Box>
                    {/* Energieausweis Gebäudenutzfläche */}
                    <Box sx={{ ml: 1, mb: 1, width: "50%" }}>
                        <FloatInput
                            // variant={editModeBuildingEnergyCertificate ? "outlined" : "filled"}
                            label="Gebäudenutzfläche"
                            value={energyCertificate.usable_area}
                            onChange={(value) =>
                                setEnergyCertificate((certificate) => {
                                    certificate.usable_area = value;
                                })
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                            }}
                            disabled={!editModeBuildingEnergyCertificate}
                            size="small"
                            validate={(val) => {
                                return true;
                            }}
                            error={errorBuildingEnergyCertificate.usable_area}
                            setError={(val) => {
                                setErrorBuildingEnergyCertificate((err) => {
                                    err.usable_area = val;
                                });
                            }}
                        />
                    </Box>
                    {/* Energieausweis Mehrere Gebäude zuweisen */}
                    <Box sx={{ mb: 1, width: "100%" }}>
                        <BasicSelectField
                            FormControlProps={{}}
                            InputLabelProps={{
                                id: "energy-certificate-related-buildings-label",
                                sx: {
                                    mt: !energyCertificate.related_buildings ? -1 : 0,
                                },
                            }}
                            multiple
                            // variant={editModeBuildingEnergyCertificate ? "outlined" : "filled"}
                            labelId="energy-certificate-related-buildings-label"
                            id="energy-certificate-related-buildings-select"
                            value={energyCertificate.related_buildings}
                            onChange={(e) =>
                                setEnergyCertificate((certificate) => {
                                    certificate.related_buildings =
                                        typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value;
                                })
                            }
                            label="Zugehörige Gebäude"
                            disabled={!editModeBuildingEnergyCertificate}
                            sx={{ width: "506px" }}
                            size="small"
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                        width: 250,
                                    },
                                },
                            }}
                            defaultValue={[currentBuilding.id]}
                            renderValue={(selected) => (
                                <>
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={buildings.find((item) => item.id === value)?.address}
                                            />
                                        ))}
                                    </Box>
                                </>
                            )}
                            customMenuItems={buildings.map(
                                (building) =>
                                    building.id !== -1 && (
                                        <MenuItem key={building.id} value={building.id} sx={{ height: "25px" }}>
                                            <Checkbox
                                                checked={energyCertificate.related_buildings.indexOf(building.id) > -1}
                                            />
                                            <ListItemText primary={building.address} />
                                        </MenuItem>
                                    )
                            )}
                        />
                    </Box>
                </Grid>
            </Box>
        </>
    );

    return (
        <Box sx={{ mt: 3 }}>
            <Accordion sx={{ width: "160ch" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <DescriptionIcon
                        sx={{
                            fontSize: 30,
                        }}
                    />{" "}
                    <Typography
                        sx={{
                            ml: 2,
                            fontSize: 20,
                            fontWeight: "bold",
                        }}
                    >
                        Energieausweis
                    </Typography>
                    {!energyCertificate.file_url && (
                        <CustomTooltip title="Zu diesem Gebäude ist noch keine Energieausweis-Datei hinterlegt.">
                            <ReportProblemTwoToneIcon sx={{ color: "#ff9e17", fontSize: 23, mt: 0.6, ml: 2 }} />
                        </CustomTooltip>
                    )}
                    {energyCertificate &&
                        energyCertificate.valid_until_date &&
                        energyCertificate.valid_until_date < new Date() && (
                            <CustomTooltip title="Der hinterlegte Energieausweis ist abgelaufen.">
                                <NotificationImportantTwoToneIcon
                                    sx={{ color: "#ff1745", fontSize: 23, mt: 0.6, ml: 2 }}
                                />
                            </CustomTooltip>
                        )}
                </AccordionSummary>
                <AccordionDetails>
                    <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                    <Box sx={{ ml: 0 }}>
                        <div
                            sx={{
                                marginLeft: "auto",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <Fab
                                variant="extended"
                                size="medium"
                                color={editModeBuildingEnergyCertificate ? "default" : "secondary"}
                                onClick={() => {
                                    handleEditMode();
                                }}
                                sx={{ marginRight: "1rem", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                {editModeBuildingEnergyCertificate ? <></> : <EditIcon sx={{ mr: 1 }} />}
                                {editModeBuildingEnergyCertificate ? "Abbrechen" : "Bearbeiten"}
                            </Fab>
                            {editModeBuildingEnergyCertificate && (
                                <Fab
                                    disabled={
                                        Object.keys(errorBuildingEnergyCertificate).length > 0 &&
                                        !Object.values(errorBuildingEnergyCertificate).every((item) => item === false)
                                    }
                                    variant="extended"
                                    size="medium"
                                    color={editModeBuildingEnergyCertificate ? "secondary" : "default"}
                                    onClick={() => handleSave()}
                                    sx={{ marginRight: "1rem", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                >
                                    <SaveIcon sx={{ mr: 1 }} />
                                    Speichern
                                </Fab>
                            )}
                        </div>
                    </Box>
                    {/* display energy certificate data */}
                    {energyCertificateLoading || !energyCertificate ? LoadingSpinner : EnergyCertificateDisplay}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
